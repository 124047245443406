@import "./mixins.scss";
@import "./variable.scss";

.img-wraps {
    position: relative;
    display: inline-block;

    font-size: 0;
}

.img-wraps .closes {
    position: absolute;
    top: 3px;
    right: 5px;
    z-index: 100;
    background-color: #FFF;
    padding: 4px 3px;

    color: red;
    font-weight: bold;
    cursor: pointer;

    text-align: center;
    font-size: 23px;
    line-height: 10px;
    border-radius: 50%;
    border: 1px solid red;
}

.img-wraps:hover .closes {
    opacity: 1;
}

.editable-cell {
    position: relative;
}

.editable-cell-value-wrap {
    padding: 5px 12px;
    cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
    padding: 4px 11px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
}

[data-theme='dark'] .editable-row:hover .editable-cell-value-wrap {
    border: 1px solid #434343;
}

#root {
    height: 100%;
}

html {
    font-size: 62.5%;
    font-family: "Roboto", sans-serif;
}

h1,
p,
span,
label {
    margin: 0;
    font-family: "Roboto", sans-serif;
}

body {
    margin: 0;
    font-weight: 400;
    font-size: 1.6rem;
    font-family: "Roboto", sans-serif;
    background-color: rgba(246, 246, 247, 1);
}

.pro-menu-card {
    >.ant-card-head {
        padding: 0 2.4rem 0 0;

        .ant-card-head-title,
        .ant-card-extra {
            padding: 0;

            .ant-menu {
                border: 0;
                margin-bottom: 0.1rem;

                .ant-menu-item {
                    padding: 1rem 1.6rem;
                    margin-right: 1.6rem;

                    &:after {
                        left: 0;
                        right: 0;
                    }
                }
            }
        }
    }
}

.buttonStyle ul li:first-child{
        margin-top: 8px;
}

.w-full {
    width: 100% !important;
}

.h-full {
    height: 100% !important;
}

@mixin text-base {
    &.border {
        border-bottom: 1px solid;
    }

    &.italic {
        font-style: italic;
    }

    &.light {
        color: #78909c;
    }

    &.danger {
        color: #ff4d4f;
    }

    &.success {
        color: #87d068;
    }

    &.semi-bold {
        font-weight: 500;
    }

    &.bold {
        font-weight: 700;
    }

    &.normal {
        font-weight: 400;
    }
}

a,
.text-link {
    color: #202323;
    cursor: pointer;

    &:hover {
        color: $PRIMARY_COLOR !important;
    }

    &.no-border:not(:hover) {
        border: none;
    }

    &.reverse {
        color: $PRIMARY_COLOR;
    }

    @include text-base;
}

.text-small {
    line-height: 1.2;
    font-size: 1.2rem;
    @include text-base;
}

.text-xl {
    line-height: 1.6;
    font-size: 1.4rem;
    @include text-base;
}

.text-2xl {
    line-height: 1.6;
    font-size: 1.6rem;
    @include text-base;
}

.text-3xl {
    line-height: 1.6;
    font-size: 1.8rem;
    @include text-base;
}

.text-4xl {
    line-height: 1.6;
    font-size: 2.5rem;
    @include text-base;
}

.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.cursor-pointer {
    cursor: pointer;
}

.capitalize {
    text-transform: capitalize;
}

.uppercase {
    text-transform: uppercase;
}

.flex {
    display: flex;
}

.flex-column {
    flex-direction: column;
}
.flex-row {
    flex-direction: row;
}
.block {
    display: block;
}

.inline {
    display: inline;
}

.hidden {
    display: none;
}

.table {
    display: table;
}

.justify-between {
    justify-content: space-between;
}

.justify-center {
    justify-content: center;
}

.justify-end {
    justify-content: flex-end;
}
.align-self-center {
    align-self: center;
}

.overflow-ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.line-clamp {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

.ant-form-item {
    margin-bottom: 1.6rem;

    &.no-margin {
        margin-bottom: 0;
    }
}

.ant-card.no-padding {
    >.ant-card-body {
        padding: 0;
    }
}

.ant-modal.no-padding {
    .ant-modal-body {
        padding: 0;
    }
}

.pro-card-table {
    .ant-card-body {
        padding: 0;

        .ant-card-loading-content {
            padding: 1.6rem 2.4rem;
        }
    }
}

.ant-table-wrapper{
    .ant-spin-blur::after{
        opacity: 0;
    }
    .ant-spin-container.ant-spin-blur{
        opacity: 1;
    }
}

.ant-table {
    overflow-x: auto;
}

.ant-table-thead>tr>th {
    border-top: 1px solid rgb(225, 227, 229);
    border-bottom: 1px solid rgb(225, 227, 229);
}

.ant-table-pagination.ant-pagination {
    margin: 1.6rem !important;
}

.ant-card {
    .ant-card-head {
        margin-bottom: 0;
    }

    .ant-card-body {
        .ant-menu-horizontal {
            border-bottom: 0;
        }
    }

    &.ant-card-bordered {
        border-color: rgb(225, 227, 229);
    }

    .ant-card-loading-content {
        padding: 1.6rem 2.4rem;
    }

    .ant-table-thead>tr>th {
        border-top: 0;
    }

    .ant-table-thead>tr>th:not(.ant-table-selection-column),
    .ant-table-tbody>tr>td:not(.ant-table-selection-column) {
        &:first-child {
            padding-left: 2.4rem;
        }

        &:last-child {
            padding-right: 2.4rem;
        }
    }
}

.ant-page-header {
    padding-top: 0 !important;
    padding-left: 0 !important;
    margin-top: -1.2rem !important;

    .ant-page-header-heading {
        align-items: center;
    }
}

@include list-loop(".pt-", "padding-top");
@include list-loop(".mt-", "margin-top");
@include list-loop(".pb-", "padding-bottom");
@include list-loop(".mb-", "margin-bottom");

.app-layout {
    min-height: 100%;

    .ant-layout-header {
        width: 100%;
        height: auto;
        line-height: 1.6;
        padding: 1rem 1.6rem;
        background-color: #ffffff;
        border-bottom: 1px solid rgb(225, 227, 229);

        .header-menu {
            cursor: pointer;
            min-width: 3.6rem;
            border-radius: 3px;
            padding: 0.8rem 1.2rem;
            transition: background-color 0.1s;

            &:hover {
                transition: background-color 0.1s;
                background-color: rgb(246, 246, 247);
            }
        }
    }

    .ant-layout {
        background: rgba(246, 246, 247, 1);

        .ant-layout-content {
            padding: 3rem;
        }
    }
}

.login-page {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: "white";

    .image-card {
        height: 150px;
        width: 100%;
        vertical-align: middle;
        background: url("https://i.imgur.com/Gmcqew1.png") no-repeat center center;
        background-size: 150px;
    }

    .ant-card {
        width: 36rem;
    }
}

.auction-item-horizontal-table {
    table {
        tr th {
            text-align: left;

            &.text-center {
                text-align: center;
            }
        }

        tr {
            td {
                white-space: normal !important;

                &:not(:last-child) {
                    border-right: 1px solid #f0f0f0 !important;
                }
            }
        }

        th {
            border-bottom: 1px solid #f0f0f0 !important;

            &:not(:last-child) {
                border-right: 1px solid #f0f0f0 !important;
            }
        }

        .component-col {
            border: 0 !important;
            padding: 0 !important;

            th {
                border-left: 0 !important;
            }

            td {
                border-right: 0 !important;
            }
        }

        .ant-image {
            width: 100%;

            .ant-image-img {
                height: 10rem;
                object-fit: contain;
            }
        }

        .component-img {
            height: 17rem !important;
        }

        .auction-img {
            height: 11rem !important;
        }

        .ant-btn {
            display: block;
            margin: 0 auto;
        }
    }
}

.horizontal-table-wrapper {
    overflow-x: auto;

    table {
        width: auto !important;
        margin: 1.6rem 1.6rem 0 1.6rem;

        tbody {
            vertical-align: top !important;
        }

        tr th {
            white-space: pre;

            &.text-center {
                text-align: center;
            }
        }

        tr {
            td {
                width: 18rem;
                white-space: pre;
                padding: 0.4rem 1.2rem;
                border: 1px solid #f0f0f0;

                &:not(:last-child) {
                    border-right: 0;
                }
            }
        }

        th {
            padding: 0.4rem 1.2rem;
            border: 1px solid #f0f0f0;
            border-bottom: 0;

            &:not(:last-child) {
                border-right: 0;
            }
        }
    }

    .pagination {
        padding: 1.6rem;
    }
}

.auction-item-list {
    .ant-card-head-wrapper {
        align-items: flex-start;

        .ant-col,
        .ant-form-item-label>label {
            font-weight: 400;
        }
    }

    .table-header-right-col {
        flex: 1;
    }
}

.auction-item-edit {
    .components-card {
        padding: 2rem;
        margin: 2rem 0;
        border-radius: 6px;
        position: relative;
        border: 2px solid $PRIMARY_COLOR;
        background-color: rgb(251, 251, 253);

        .delete-trigger {
            top: 1.2rem;
            right: 2.2rem;
            font-size: 2rem;
            position: absolute;
        }

        .collapse-trigger {
            top: 1.2rem;
            right: 5.2rem;
            font-size: 2rem;
            cursor: pointer;
            position: absolute;
        }

        .attribute-col {
            &.hidden {
                display: none;
            }
        }
    }

    .images-col {
        top: 0;
        right: 0rem;
        width: 38rem;
        z-index: 999;
        position: absolute;

        .selected-image {
            height: 25rem;
            object-fit: contain;
            margin-bottom: 2.4rem;
        }

        .ant-space {
            display: flex;
            margin-bottom: 1.6rem;

            .image-item {
                width: 8rem;
                height: 8rem;
                cursor: pointer;
                object-fit: contain;

                &.selected {
                    border: 2px solid $PRIMARY_COLOR;
                }
            }
        }

        .user-upload {
            display: table;
        }
    }

    .is-approved-checkbox {
        .ant-checkbox-inner {
            width: 2rem;
            height: 2rem;
        }
    }
}

.auction-item-add-attr-modal {
    .picklist-option-item {
        width: 100%;

        .ant-space-item:first-child {
            flex: 1;
        }
    }
}

.attr-value-dropdown {
    .ant-divider {
        margin: 1.2rem 0;
    }

    .add-attr-value {
        display: flex;
        align-items: center;
        padding: 0 1.2rem 1.2rem;

        input {
            flex: 1;
            margin-right: 1.2rem;
        }
    }
}

.customer-assignment-modal {
    .ant-card {
        .ant-table {
            .ant-table-expanded-row {
                >td {
                    padding: 1.6rem !important;

                    .ant-table {
                        margin: 0;
                    }
                }
            }
        }
    }
}

.customer-collection-card {
    .ant-table {
        .ant-table-expanded-row {
            >td {
                padding: 1.6rem !important;

                .ant-table {
                    margin: 0 !important;
                }
            }
        }
    }

    .comparable-row-tables {
        .customer-collection-simple-table {
            background: #ebe8e8;
        }
    }

    .comparable-row-item {
        >.ant-table-cell {
            background: #ebe8e8;
        }
    }

    .customer-collection-simple-table {
        width: auto !important;
        margin-bottom: 1.6rem;

        tbody {
            vertical-align: top !important;
        }

        .item-name-cell,
        .notes-cell {
            width: 30rem !important;
        }

        .action-cell {
            padding: 0;

            .ant-btn {
                border-radius: 0;
            }
        }

        tr {
            td {
                width: 18rem;
                padding: 0.4rem 1.2rem;
                border: 1px solid #f0f0f0;

                &:not(:last-child) {
                    border-right: 0;
                }
            }
        }

        th {
            padding: 0.4rem 1.2rem;
            border: 1px solid #f0f0f0;
            border-bottom: 0;

            &:not(:last-child) {
                border-right: 0;
            }
        }
    }
}

@media only screen and (min-width: 600px) and (max-width: 1024px) {
    .app-layout {
        .ant-layout-sider {
            flex: 20rem !important;
            width: 20rem !important;
            min-width: 20rem !important;
            max-width: 20rem !important;

            .ant-menu-item {
                padding: 0 1rem !important;

                .ant-menu-item-icon {
                    display: none !important;
                }

                .ant-menu-title-content {
                    margin: 0;
                }
            }

            &.ant-layout-sider-collapsed {
                display: none;
            }
        }

        .jewelry-filter-component {
            display: block;
        }

        .table-header-right-col {
            .ant-form {
                >.ant-row {
                    display: block;

                    >.ant-col {
                        &:first-child {
                            margin-bottom: 1.2rem;
                        }

                        align-items: flex-start !important;
                    }
                }
            }
        }

        .auction-management {
            .ant-card-head-wrapper {
                display: block;

                .ant-card-head-title {
                    padding-bottom: 0;
                }

                .ant-card-extra {
                    float: none;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    >div {
                        margin: 0 !important;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .app-layout {
        .ant-layout-sider {
            flex: 16rem !important;
            width: 16rem !important;
            min-width: 16rem !important;
            max-width: 16rem !important;

            .ant-menu-item {
                padding: 0 1rem !important;

                .ant-menu-item-icon {
                    display: none !important;
                }

                .ant-menu-title-content {
                    margin: 0;
                }
            }

            &.ant-layout-sider-collapsed {
                display: none;
            }
        }

        .ant-layout {
            .ant-layout-content {
                padding: 1rem 1.6rem;
            }

            .ant-card {
                .ant-card-head {
                    padding: 0 1.6rem;
                }
            }
        }
    }

    .ant-form {
        .ant-row {
            flex-wrap: wrap;

            .ant-col {
                max-width: 100%;
                flex: 100% !important;
            }
        }
    }

    .ant-modal {
        top: 2rem !important;

        .ant-modal-content {
            .ant-modal-header {
                padding: 1rem 1.6rem;
            }

            .ant-modal-close {
                top: -0.4rem;
            }

            .ant-modal-body {
                padding: 1rem 1.6rem;
            }
        }
    }

    .auction-item-edit {
        .images-col {
            width: auto;
            position: unset;
        }
    }

    .jewelry-filter-component {
        flex-wrap: wrap;
    }

    .jewelry-range-filter {
        .ant-row {
            flex-flow: row wrap;

            .ant-col {
                flex: 50% !important;

                &:last-child {
                    text-align: right;
                }
            }
        }
    }

    .auction-management,
    .customer-collection-card {
        .ant-card-head-wrapper {
            display: block;

            .ant-card-head-title {
                display: block;
                padding-bottom: 0;
            }

            .ant-card-extra {
                float: none;
                padding: 0;
            }

            .ant-space,
            .ant-row {
                display: block;
            }

            .ant-space-item {
                margin-bottom: 1.2rem;
            }

            .ant-input,
            .ant-picker,
            .ant-select {
                width: 100% !important;
            }
        }
    }

    .customer-collection-card {
        .ant-row {
            >.ant-col {
                &:last-child {
                    .ant-space {
                        display: flex;
                        margin-top: 1.2rem;
                        align-items: flex-start;
                        flex-direction: column-reverse;

                        .ant-space-item {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }

    .table-header-right-col {
        display: block;
        margin-top: 1.2rem;

        .ant-row {
            display: block;

            .ant-col {
                display: block;

                .ant-space {
                    width: 100%;
                    display: block;
                    margin-bottom: 0 !important;

                    .ant-space-item {
                        margin-bottom: 1.2rem;
                    }
                }

                .ant-input,
                .ant-picker,
                .ant-select {
                    width: 100% !important;
                }
            }
        }
    }
}
.p-2\.5 {
    padding: 0.625rem;
}
.p-1 {
    padding: 0.25rem;
}
.mt-4 {
    margin-top: 1rem
}
.mb-4 {
    margin-bottom: 1rem;
}